
//import DownloadingIcon from '@mui/icons-material/Downloading';
//import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
//import TranscribeIcon from '@mui/icons-material/Transcribe';
//import CommentIcon from '@mui/icons-material/Comment';
//import TitleIcon from '@mui/icons-material/Title';
//import DescriptionIcon from '@mui/icons-material/Description';

export const logo = '/analyse everything logo.png';
export const googleSignInLogo = '/web_dark_rd_SI@4x.png';

export const downloadCategories = [
  { name: 'All', wiki: 'All'},
  //{ name: 'Cars',},
  { name: 'Comedy', wiki: 'https://en.wikipedia.org/wiki/Comedy'},
  //{ name: 'Food',},
  //{ name: 'Finance',},
  { name: 'Fitness', wiki: 'https://en.wikipedia.org/wiki/Physical_fitness'},
  //{ name: 'Health',},
  { name: 'Knowledge', wiki: 'https://en.wikipedia.org/wiki/Knowledge'},
  { name: 'Lifestyle', wiki: 'https://en.wikipedia.org/wiki/Lifestyle_(sociology)'},
  //{ name: 'Podcasts',},
  { name: 'Politics', wiki: 'https://en.wikipedia.org/wiki/Politics'},
  { name: 'Society', wiki: 'https://en.wikipedia.org/wiki/Society'},
  { name: 'Technology', wiki: 'https://en.wikipedia.org/wiki/Technology'},
];

export const searchCategories = [
  { name: 'Transcripts', },
  { name: 'Comments',},
  { name: 'Titles',},
  { name: 'Descriptions',},
];
